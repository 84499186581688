import { j as e } from "./jsx-runtime-B6kdoens.js";
import r, { Children as N, isValidElement as h, forwardRef as f } from "react";
import { B as u } from "./Button-DhTfdjdw.js";
import { D as d } from "./DropdownMenu-BOLwas6W.js";
import { I as w } from "./Icon-B2GFsdMo.js";
import { T as j } from "./Text-DOFuHqUo.js";
import { g as k } from "./utils-CJ9afRe1.js";
const x = (n) => {
  const o = N.only(n.children);
  return /* @__PURE__ */ e.jsx(
    u,
    {
      asChild: !0,
      variant: "ghost",
      className: "w-[28px] h-[28px] p-0 shrink-0 cursor-pointer",
      children: /* @__PURE__ */ e.jsx("div", { children: h(o) && r.cloneElement(
        o,
        {},
        /* @__PURE__ */ e.jsx(w, { name: "NavArrowLeft", width: 20 })
      ) })
    }
  );
};
x.displayName = "BreadcrumbsBackButton";
const g = f((n, o) => /* @__PURE__ */ e.jsx(j, { ref: o, as: "div", className: n.className, children: n.children }));
g.displayName = "Breadcrumb";
const C = (n, o) => {
  const [s, t] = r.useState(!1), i = r.useRef(null);
  r.useImperativeHandle(o, () => ({
    openDropdown: () => t(!0),
    closeDropdown: () => t(!1)
  }));
  const p = r.Children.toArray(n.children).filter(
    (a) => h(a) && typeof a.type != "string" && a.type.displayName === "Breadcrumb"
  ), [l, b] = r.useState(p), c = r.useCallback(() => {
    const a = i.current;
    if (!a) return;
    a.scrollWidth > a.clientWidth && l.length > 1 && b(
      (m) => m.slice(1, m.length)
    );
  }, [l.length]);
  r.useLayoutEffect(() => (c(), window.addEventListener("resize", c), () => {
    window.removeEventListener("resize", c);
  }), [c]);
  const B = p.slice(
    0,
    p.length - l.length
  ), y = k(
    n.children,
    "BreadcrumbsBackButton"
  );
  return {
    dropdownItems: B,
    children: l,
    containerRef: i,
    backButton: y,
    isDropdownOpen: s,
    onDropdownOpenChange: t
  };
}, v = f((n, o) => {
  const s = C(n, o);
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      className: "flex gap-2 w-full flex-row items-center py-2",
      ref: s.containerRef,
      children: [
        s.backButton,
        s.dropdownItems.length > 0 && /* @__PURE__ */ e.jsxs(
          d,
          {
            open: s.isDropdownOpen,
            onOpenChange: s.onDropdownOpenChange,
            children: [
              /* @__PURE__ */ e.jsx(
                d.Trigger,
                {
                  asChild: !0,
                  children: /* @__PURE__ */ e.jsx(
                    u,
                    {
                      variant: "ghost",
                      className: "w-[28px] h-[28px] p-0 hover:bg-gray-table-header",
                      children: /* @__PURE__ */ e.jsx(w, { strokeWidth: 2, name: "MoreHoriz", width: 23, height: 23 })
                    }
                  )
                }
              ),
              /* @__PURE__ */ e.jsx(
                d.Content,
                {
                  align: "start",
                  side: "bottom",
                  className: "w-[224px] min-w-0 flex flex-col p-1",
                  sideOffset: 8,
                  children: s.dropdownItems.map((t, i) => /* @__PURE__ */ e.jsx(d.Item, { children: r.cloneElement(
                    t,
                    {
                      className: "flex overflow-hidden items-center [&>a_svg]:w-5 [&>a_svg]:h-4 [&>a_svg]:shrink-0 text-ellipsis gap-2 [&>a]:w-[224px] [&>a]:flex [&>a]:items-center [&>a]:gap-2 [&>a_p]:text-ellipsis [&>a]:overflow-hidden [&>a_p]:overflow-hidden [&>a_p]:whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
                    },
                    t.props.children
                  ) }, i))
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ e.jsx("div", { className: "flex-1 flex group min-w-0", children: s.children.map(
          (t) => r.cloneElement(t, {
            className: `${t.props.className ?? ""}
              cursor-pointer [&_svg:first-of-type]:hidden text-gray-secondary relative font-medium only:text-ellipsis only:overflow-hidden only:cursor-default last:text-black-primary first:before:hidden hover:text-black-primary whitespace-pre rounded px-1 hover:bg-gray-table-header`
          })
        ) })
      ]
    }
  );
});
v.displayName = "Breadcrumbs";
const W = Object.assign(v, {
  BackButton: x,
  Breadcrumb: g
});
export {
  W as B
};
